.product .image {
  position: relative;
  background: #fff;
  padding: 20px;
  height: 160px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}

.product .image img {
  max-width: 160px;
  min-width: 160px;
  max-height: 160px;
  min-height: 160px;
}

.product .title {
  padding: 5px 0;
}

.item {
  padding-top: 10px;
  padding-bottom: 10px;
}

.product .title {
  width: 100%;
  bottom: 0px;
  position: absolute;
  background-color: rgba(245, 245, 245, 0.9);
  left: 0px;
  right: 0px;
  padding: 5px;
}

.product:hover .x {
  background-color: transparent
}

/* .product.is-gray .image .hover-overlay .product .title {
  background-color: transparent
} */

html {
  overflow: hidden;
}

.App-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.content {
  height: calc(100vh - 60px);
  margin-top: 60px;
  display: block;
  position: relative;
  overflow-y: auto;
}

.App-header.page-- ~ .content {
  height: 100vh;
  margin-top: 0;
}

.wrap {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.loading {
  position: fixed;
  background: white;
  z-index: 999;
  overflow: visible;
  margin: auto;
  top: 65px;
  left: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}

.loader {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.product-category {
  width: 100%;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: holtzman-textured !important;
    font-size: 40px !important;
    color: #212529 !important;
    letter-spacing: 2px;
  /* border-bottom: 1px solid rgba(113,112,111, 0.5);   */
}
.bold {
  font-family: "Brendon-Grotseque-bold";
}
.md-toolbar {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  z-index: 2;
  font-size: 20px;
  min-height: 64px;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 0.95);
  vertical-align: middle;
}

.search-box {
  margin-left: 10px;
  height: 30px;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 1px solid #71706f;
  width: 100%;
  opacity: 0.6;
  background-color: inherit;
}

.search-box:focus {
  opacity: 1;
  outline-width: 0;
  border-bottom: 2px solid #71706f;
  background-color: inherit;
}

.search-box-with-icon {
  max-width: 400px;
  display: flex;
  flex-direction: row;
  min-width: 50%;
}

.like-anchor {
  background: none!important;
  color: inherit;
  border: none;
  padding: 0!important;
  font: inherit;
  /*border is optional*/
  cursor: pointer;
  margin-right: 15px;
}

.like-anchor:focus {
  outline-width: 0px;
}

.scrollable-menu {
  height: auto;
  max-height: 400px;
  overflow-x: hidden;
}

.fade-in {
  opacity: 0;
}

.fade-in.visible {
  transition: opacity .4s ease-in;
  opacity: 1;
}



nav.navbar a.nav-link {
  color: #fff;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 15px;
  font: normal normal normal 14px/1.4em Brendon-Grotseque-bold, sans-serif;
  text-transform: uppercase;
}

nav.navbar a.nav-link:hover {
  color: white;
  border-bottom: 1px solid white;
}

nav.navbar {
  height: 60px;
  justify-content: center;
  align-items: center;
  text-align: justify;
  text-align-last: center;
  margin-top: 0px;
  transition: all 200ms ease;
  background: #686A6A;
}
nav.navbar > .container-fluid {
  max-width: 1300px;
  margin: 0 auto;
}
nav.navbar.scrolled {
  margin-top: 0px;
}
.App-header.page-- .navbar:not(.scrolled) {
  background: transparent;
  margin-top: 20px;
}

.text-small {
  display: none;
}
@media screen and (max-width: 600px){
  .App-header.page-- .navbar:not(.scrolled) {
    margin-top: 0px;
  }
  
  .text-small {
    display: inline;
  }
  .text-small + span {
    display: none;
  }
}

.user-section {
  display: flex;
  gap: 5px;
}

.user-section i {
  height: 21px;
  transform: translateY(0px);
}
.user-section .desktop-text {
  display: none;
}

@media screen and (min-width: 992px){
  .hidden-desktop {
    display: none;
  }
}
.hidden-mobile {
  @media screen and (max-width: 991px){
    display: none;
  }
}


@media screen and (min-width: 600px){

  .user-section span:empty {
    display: none;
  }

  .user-section .desktop-text {
    display: inline;
    padding-right: 5px;
  }

  .user-section .mobile-text {
    display: none;
  }
  

}

.user-section span {
  color: white !important;
}



a.btn-grey {
  background-color: #686A6A !important;
  color: white !important;
}
nav.navbar .nav-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: justify;
  text-align-last: center;
}

.home-title {
  text-align: center;
  color: white;
  position: relative;
  z-index: 1;
  font-family: "Heinberg";
  font-weight: normal;
  font-style: normal;
  font-size: clamp(1.25rem, 0.9524rem + 1.4881vw, 1.875rem);
  line-height: 1.4;
  margin-bottom: 0;
  
}
.home-bg-image-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  -webkit-filter: blur(0px);
  filter: blur(0px);
  transition: all 400ms;
  transform:scale(1.01);
}

#how-it-works {
  scroll-margin-top: 60px;
}

.home-bg-image-img  + div { 
  opacity: 1;
}

.home-bg-image-img.loaded  + div {  
  opacity: 0;
}

.home-title span span {
  /*color: transparent;*/
}
.home-title .thirsty-rough span {
  font-size: clamp(2.2rem, 1.6071rem + 4.4643vw, 4.375rem);
  white-space: nowrap;

}
.home-title .heinberg {
  position: relative;
}
.home-title .heinberg:after {
  //background-image: url('/images/statt.svg');
  height: 100%;
  width: 100%;
  position: absolute;
  /*content: "";*/
  background-repeat: no-repeat;
  background-size: contain;
  left: 0;
  pointer-events: none;
}

.home-title .thirsty-rough span:after {
  height: 100%;
  width: 100%;
  position: absolute;
  /*content: "";*/
  background-repeat: no-repeat;
  background-size: contain;
  left: 0;
  top: 30px;
    pointer-events: none;

}

.home-title span {
}

.thirsty-rough {
  font-family: "Thirsty-Rough";
  font-weight: normal;
  font-style: normal;
}

.home-actions {
  margin-top: 5vh;
  display: flex;
  gap: 20px;
  /*padding-bottom: 10vh;*/
  position: relative;
  z-index: 1;
  flex-wrap: wrap;
  justify-content: center;

  .btn-blue {
    line-height: 50px !important;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.home-logo {
  position: absolute;
  z-index: 1;
  height: 160px;
  width: 160px;
  bottom: 80px;
  right: 80px;
  transform: rotate(-20deg)


}

@media screen and (max-height: 900px) and (max-width: 1200px) {
  .home-logo {
    bottom: 20px;
    right: 20px;
    height: 100px;
    width: 100px;
  }
}

.home-actions a {
  white-space: nowrap;
  background-color: transparent;
  width: auto;
  box-shadow: none;
  border: 2px solid white;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  padding: 10px 15px;
  font-weight: bold;
  letter-spacing: 2px;
}

@media screen and (max-width: 600px){
  .home-actions {
    gap: 15px;
  }
  .home-actions a {
    font-size: 14px;
  }
}

.home-actions a:hover {
  transform: scale(1.05);
  text-decoration: none;
}

.home-actions a:first-child {
  background-color: white;
  color: black;
  font-family: "Brendon-Grotseque-bold";
}

.home-first-image-div {
  margin-top: 5vh;
  color: white;
  /* margin: 30px 0px 0px calc((100% - 900px) * 0.5); */
  text-align: justify;
  text-align-last: center;
  position: relative;
  z-index: 1;
  line-height: 1.4;

}

@media screen and (max-width: 600px){
  .home-first-image-div p {

  font-size: 18px;
  }
}

.home-first-image-header {
  font-size: 42px;
  color: #000000;
  color: black;
  font-family: 'holtzman-textured';
  text-transform: uppercase;
  text-align: justify;
  text-align-last: center;
}

.home-first-image-p {
  padding-top: 0px;
  padding-bottom: 0px;
  color: white;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Brendon-Grotseque-regular';
  text-align: justify;
  text-align-last: center;
  font-size: 22px;
}

.custom-control-lg .custom-control-label::before, .custom-control-lg .custom-control-label::after {
  top: 0.1rem !important;
  left: -2rem !important;
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.custom-control-lg .custom-control-label {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
}

.item-header {
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Brendon-Grotseque-regular";
}

.item-header-font {
  font-family: "Brendon-Grotseque-regular";
  color: #000000;
}

.item-main-header {
  padding-left: 4px;
  padding-bottom: 4px;
  font-size: 16px;
  text-transform: none;
  font-family: "Brendon-Grotseque-bold";
  color: #2a4a91
}

.item-button {
  list-style: none;
  border-top: 0 none;
  border-left: 0 none;
  border-right: 0 none;
  padding-top: 8px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 6px;
  border-bottom: 1px solid #ececef!important;
  font-size: 0.9em;
  color: #222;
  cursor: pointer;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
  -webkit-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  margin-bottom: 0px;
}

.item-button.disabled {
  opacity: 0.6;
}

.item-button:hover {
  background-color: #fff;
  -webkit-transform: translateY(-3px);
  -moz-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.item-button.disabled:hover {
  background-color: #fff;
  cursor: default;
}

.item-button.active, .item-button.active:hover {
  list-style: none;
  border-top: 1px solid white;
  border-left: 0 none;
  border-right: 0 none;
  text-transform: uppercase;
  padding-top: 8px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 8px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #ececef;
  font-size: 0.9em;
  color: #222;
  transform: translateY(0);
  -webkit-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  cursor: default;
  background-color: rgba(176,200,219, 0.6);
}

.item-checkbox {
  width: 100%;
  margin-top: 6px;
  margin-bottom: 0px;
  padding-top: 4px;
  padding-bottom: 2px;
  transform: scale(1, 1);
  transition: all 0.2s ease-out;
  padding-left: 25px;
}

.item-checkbox label {
  margin-top: 0;
}

.item-checkbox:hover {
  transition: all 0.2s ease-out;
  transform: scale(1, 1);
  background: rgba(0, 0, 0, 0.1);
}

.checkbox input[type="checkbox"] {
  opacity: 0;
  width: 0px;
  height: 0px;
}

.checkbox label {
  position: relative;
  display: inline-block;
  /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
  padding-left: 22px;
  padding-top: 4px;
  padding-bottom: 12px;
  margin-bottom: 0px;
}

.checkbox label::before, .checkbox label::after {
  position: absolute;
  content: "";
  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer box of the fake checkbox*/

.checkbox label::before {
  /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
   *to vertically center it.
   */
  top: 0px;
  height: 14px;
  width: 14px;
  border: 1px solid;
  left: 0px;
  top: 1px;
}

/*Checkmark of the fake checkbox*/

.checkbox label::after {
  height: 5px;
  width: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
  left: 3px;
  top: 4px;
}

/*Hide the checkmark by default*/

.checkbox input[type="checkbox"]+label::after {
  content: none;
}

/*Unhide on the checked state*/

.checkbox input[type="checkbox"]:checked+label::after {
  content: "";
}

/*Adding focus styles on the outer-box of the fake checkbox*/

.checkbox input[type="checkbox"]:focus+label::before {
  outline: rgb(59, 153, 252) auto 5px;
}

.container-shopping-cart {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 1200px) {
  .container-shopping-cart {
    max-width: 1140px;
  }
}

@media (min-width: 1300px) {
  .container-shopping-cart {
    max-width: 1240px;
  }
}

@media (min-width: 1400px) {
  .container-shopping-cart {
    max-width: 1340px;
  }
}

@media (min-width: 1500px) {
  .container-shopping-cart {
    max-width: 1440px;
  }
}

@media (min-width: 1600px) {
  .container-shopping-cart {
    max-width: 1540px;
  }
}

@media (min-width: 1700px) {
  .container-shopping-cart {
    max-width: 1640px;
  }
}

.shopping-item-section {
  font-size: 13px;
  padding-top: 10px;
  padding-bottom: 20px;
  //min-height: 200px;
  max-width: 300px;
}

@media (max-width: 992px) {
  .shopping-item-section {
    max-width: 100%;
    font-size: 16px;
    max-height: 100%;
    min-height: 0px;
    //padding-bottom: 0;
  }
  .cart-item .col-lg:not(:first-child) .shopping-item-section {
    padding-top: 0
  }

  .cart-item .image-price-container + .col-lg  {
    padding-top:15px;
  }

  .col-lg:nth-last-child(2) .shopping-item-section {
    //padding-bottom: 20px;
  }
}

.quantity-button {
  min-width: 18px;
  max-width: 28px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  margin: 0;
  background-color: #e0e0e0;
  border-radius: 0 !important;
  background-color: #e0e0e0;
  border: none;
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

.quantity-button:focus {
  outline: 0;
}

/* Ripple effect */

.ripple {
  background-position: center;
  transition: background 0.8s;
}

.ripple:hover {
  background: #ececec radial-gradient(circle, transparent 1%, #ececec 1%) center/15000%;
}

.ripple:active {
  background-color: #d1d1d1;
  background-size: 100%;
  transition: background 0s;
}

.quantity-control {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: stretch;
  border-radius: 5px;
  max-height: 40px;
  border: none;
  overflow: hidden;

  svg[data-icon="info-circle"] {
    margin-left: 10px !important;
  }
}

.quantity-number {
  min-width: 20px;
  font-weight: bold;
  display: inline-block;
  max-width: 40px;
  text-align: center;
  background: #fff;
  height: 28px;
  line-height: 28px;
}

.quantity-number .lg {
  height: 40px;
}

.quantity-label {
  padding-top: 7px;
  margin-right: 7px;
  text-transform: none;
}
.item-checkbox .checkbox label::before {
  height: 20px;
  width: 20px;
  border: 1px solid rgba(113,112,111, 0.5);
  border-radius: 3px;
  top: -1px;
  left: 3px;
}

.item-checkbox .checkbox input:checked label::before {
  content: "";
}

.item-checkbox .checkbox input:checked + label::after {
  content: "✔";
  font-size: 11.7px;
  left: 7px;
  top: 0px;
  transform: rotate(0);
  height: 14px;
  width: 14px;
  border: 1px solid transparent;
  border-radius: 3px;
  border-bottom: 0;
  position: absolute;
  display: block;
}

.item-checkbox .checkbox label {
  padding-right: 7px;
}

.shopping-item-section .item-header {
  position: relative;
}

.shopping-item-section .item-header:before{  
  height: 12px;
  width: 12px;
  border: 1px solid rgba(113,112,111, 0.5);
  border-radius: 3px;
  content: "";
  left: 2px;
  top: 1px;
  display: block;
  position: absolute;

  @media screen and (max-width: 992px){
    top: 4px;
  }
}



.shopping-item-section .item-button.active .item-header:after {
  height: 8px;
  width: 8px;
  border: 1px solid transparent;
  border-radius: 3px;
  content: "✔";
  font-size: 11.7px;
  left: 2px;
  top: 3px;
  display: block;
  position: absolute;

  @media screen and (max-width: 992px){
    top: 1px !important;
  }
}
.shopping-item-section .item-button.single .item-header:before {
  border-radius: 50%;
}

.shopping-item-section .item-button.single.active .item-header:after {
  border-radius: 50%;
  background-color: #000000;
  transform: scale(0.6);
  top: 3px;
  left: 4px;
  content: "";

  @media screen and (max-width: 992px){
    top: 6px !important;
  }

}

.shopping-item-section .item-button:not(.single).active .item-header:after {
  height: 14px;
  width: 14px;
  left: 2px;
  top: -2px;
  text-align: center;
} 

.shopping-item-section .item-header {
  padding-left: 21px;
  letter-spacing: 0.3px;
}
.close-btn-item {
  border: none;
  background-color: transparent;
  color: black;
  padding: 7px 14px;
  font-size: 12px;
  cursor: pointer;
  opacity: 0.6;
  top: 0px;
  right: 0px;
  position: absolute;
  font-weight: 400;
  min-width: 30px;
}

.close-btn-item:focus {
  outline: 0;
}

.price-cart-item-right {
  padding-top: 14px;
}

.bestseller {
  height: 270px;
  overflow: hidden;

  @media (max-width: 992px) {
    height: auto;
  }
}

@media (max-width: 1300px) {
  .close-btn-item-sm {
    visibility: hidden;
  }
  .price-cart-item-right {
    visibility: hidden;
    display: none;
  }
  .price-cart-item-left {
    top: 20px;
    position: absolute;
    right: 40px;
  }
  .quantity-price-part {
    top: 0px;
    position: relative;
    margin-bottom: 10px;
  }
  .note-btn-item-sm {
    visibility: hidden;
  }
}

.checkout-btn.loading-btn span {
  opacity: 0;
}

.checkout-btn.loading-btn {
  position: relative;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.checkout-btn.loading-btn:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #686A6A;
  animation: spinner .6s linear infinite;
}

@media (max-width: 516px) {
  .image-price-container {
    height: 174px;
  }
  .price-cart-item-left {
    top: 60px;
    position: absolute;
    right: 40px;
  }
  .quantity-price-part {
    top: 100px;
    position: absolute;
    left: 20px;
  }
}

.note-btn-item-lg {
  position: absolute;
  right: 36px;
  bottom: 15px;
}

.note-btn-item-sm {
  position: absolute;
  left: 5px;
  bottom: 5px;
}

@media (min-width: 1300px) {
  .close-btn-item-lg {
    visibility: hidden;
  }
  .price-cart-item-left {
    visibility: hidden;
  }
  .note-btn-item-lg {
    visibility: hidden;
  }
}

.price-cart-item {
  font-size: 28px;
  font-weight: 600;
  color: #2a4a9a;
  font-family: "Brendon-Grotseque-bold";
  white-space: nowrap;
}

.image-price-container {
  order: 0;
  padding-top: 20px;
  max-width: 350px;
  min-width: 350px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
  min-height: 206px;
  padding-left: 160px;
  background-color: rgb(188, 182, 172, 0.5);
  font-family: "Brendon-Grotseque-regular";
  position: relative;
}

.image-price-container-image {
  height: 150px;
  width: 150px;
  margin-right: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1300px) {
  .image-price-container-image {
    height: 100px;
    width: 100px;
  }
  .image-price-container {
    padding-left: 110px;
  }
}

.price-section {
  text-align: right;
  background-color: rgb(188, 182, 172, 0.5);
  min-width: 150px;
  max-width: 150px;
  padding-top: 30px;
  position: relative;
}

@media (max-width: 1300px) {
  .image-price-container {
    max-width: 100%;
    min-width: 100%;
    padding-top: 15px;
    min-height: 110px;
    padding-bottom: 15px;
  }
  .price-section {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    padding-top: 0px;
    height: 0px;
  }
}

.quantity-price-box {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-title-product-card {
  font-family: "Brendon-Grotseque-bold";
  color: #000000;
  font-size: 0.91rem;
}

.text-muted {
  font-family: "Brendon-Grotseque-regular";
  color: #6d767f;
}

@media (min-width: 992px) {
  .register-modal .modal-xl {
    max-width: 960px;
  }
}

.register-modal .modal-body {
  padding: 0px;
}

@media (min-width: 992px) {
  .address-modal .modal-lg {
    max-width: 650px;
  }
}

.address-modal .modal-body {
  padding: 0px;
}

.small-price {
  margin-left: auto!important;
  white-space: nowrap;
}

.font-bold {
  font-family: "Brendon-Grotseque-regular";
}

.user-link {
  height: 100%;
  width: 100%;
  margin: 0 !important;
  min-width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar .cart {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-login-menu {
  top: 20px !important;
  right: 150px !important;
  position: absolute !important;
  left: unset !important;
  min-width: 100px !important;
  margin-top: 40px;
}

.error {
  background-color: #df5f4e;
  color: #fff;
}

.modal-header {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #dddad5;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
}

.modal-header .close {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: -0.8rem;
  margin-bottom: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
}

.modal-content {
  border-radius: 0%;
}

.modal-small-header {
  color: #2a4a9a
}

.btn {
  border-radius: 0%;
  padding-top: 5px!important;
  padding-bottom: 5px!important;
}

.btn-primary, .btn-template {
  color: #000000;
  background-color: #b0c8db;
  border-color: #b0c8db;
}

.btn-primary:hover, .btn-template:hover {
  color: #000000;
  background-color: #b0c8db;
  border-color: #b0c8db;
}

.btn-primary:focus, .btn-template:focus, .btn-primary.focus, .focus.btn-template {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(144, 85, 162, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(144, 85, 162, 0.5);
}

.btn-primary.disabled, .disabled.btn-template, .btn-primary:disabled, .btn-template:disabled {
  color: #000000;
  background-color: #b0c8db;
  border-color: #b0c8db;
}

.btn-primary:not([disabled]):not(.disabled):active, .btn-template:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active, .btn-template:not([disabled]):not(.disabled).active, .show>.btn-primary.dropdown-toggle, .show>.dropdown-toggle.btn-template {
  color: color-yiq(#b0c8db);
  background-color: #b0c8db;
  border-color: #b0c8db;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(144, 85, 162, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(144, 85, 162, 0.5);
}

.user {
  position: relative;

}
.user-login-menu {
  left: 50% !important;
  right: auto !important;
  top: auto !important;
  bottom: 0 !important;
  transform: translateY(calc(100% + 10px)) translateX(-50%) !important;
  margin-bottom: 0px !important;
  width: auto !important;
}

.user-login-menu .dropdown-item {
  padding: 5px 15px !important;
  margin-bottom: 0px !important;
}

.user-login-menu .dropdown-item:hover {
 color: black !important;
}

.nav-link.shopping-button:hover {
  background-color: white !important;
}

.register-form-italic-text {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.54);
  font-style: italic;
  line-height: 1;
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
  outline: 0;
}

body:not(.user-is-tabbing) button:focus, body:not(.user-is-tabbing) input:focus, body:not(.user-is-tabbing) select:focus, body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

.user-tab {
  background-color: rgb(236, 236, 234);
  min-height: calc(100vh - 254px);
}

@media (max-width: 992px) {
  .user-tab {
    min-height: 100px;
  }
}

.font_3 {
  font: normal normal normal 60px/1.4em holtzman-textured;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 60px;
  line-height: 1.4em;
  color: #000000;
}

.font_8 {
  font: normal normal normal 15px/1.4em Brendon-Grotseque-light;
  color: #2B2B2B;
}

.final-price-order {
  font-size: 22px;
  font-weight: 600;
  color: #2a4a9a;
  font-family: "Brendon-Grotseque-bold";
  white-space: nowrap;
  padding-right: 10px;
}

.order-item-header-text {
  color: #2a4a9a;
  font-family: "Brendon-Grotseque-bold";
}

.uppercase {
  text-transform: uppercase;
}

.react-grid-Grid, .react-grid-Main, .react-grid-Container, .react-grid-Canvas {
  min-height: calc( 100vh - 108px) !important;
}

.react-datepicker-ignore-onclickoutside, .react-datepicker__input-container, .react-datepicker-wrapper {
  width: 100%
}

.react-datepicker__input-container input {
  width: 100%
}

.checkbox.checbox-switch {
  /* padding-left: 0;
  padding-top: 6px; */
}

.checkbox.checbox-switch label, .checkbox-inline.checbox-switch {
  display: inline-block;
  position: relative;
  padding-left: 0;
}

.checkbox.checbox-switch label input, .checkbox-inline.checbox-switch input {
  display: none;
}

.checkbox.checbox-switch label span, .checkbox-inline.checbox-switch span {
  width: 35px;
  border-radius: 20px;
  height: 18px;
  border: 1px solid #dbdbdb;
  background-color: #e4e5e6;
  border-color: #e4e5e6;
  box-shadow: rgb(223, 223, 223) 0px 0px 0px 0px inset;
  /* transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s; */
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.checkbox.checbox-switch label span:before, .checkbox-inline.checbox-switch span:before {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  content: " ";
  top: 0;
  position: relative;
  left: 0px;
  /* transition: all 0.3s ease; */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  top: 0px;
}

.checkbox.checbox-switch label>input:checked+span:before, .checkbox-inline.checbox-switch>input:checked+span:before {
  left: 16px;
  top: 0px;
}

/* Switch Primary */

.checkbox.checbox-switch.switch-primary label>input:checked+span, .checkbox-inline.checbox-switch.switch-primary>input:checked+span {
  background-color: #20a8d8;
  border-color: #20a8d8;
  /*box-shadow: rgb(0, 105, 217) 0px 0px 0px 8px inset;*/
  /* transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s; */
}

.checkbox.checbox-switch.switch-primary label>input:checked:disabled+span, .checkbox-inline.checbox-switch.switch-primary>input:checked:disabled+span {
  background-color: #e4e5e6;
  border-color: #e4e5e6;
  /* box-shadow: rgb(109, 163, 221) 0px 0px 0px 8px inset;*/
  /* transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s; */
}

.order-details-box {
  font-size: 14px;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 5px;
  padding-bottom: 15px;
  background-color: rgb(188, 182, 172, 0.5);
  max-width: 390px;
  min-width: 390px;
}

.orders-details-box-img {
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-bottom: -26px;
}

.order-details-table {
  width: 100%;
}

.order-details-table th {
  color: #2a4a9a;
  font-size: 16px;
}

.order-details-table td {
  width: 50%;
}

nav.navbar a.nav-link.active {
  color: white !important;
  border-bottom: 1px solid;
}

.checkout-btn {
  background-color: #2a4a9a!important;
  display: inline-block;
}

.btn-outline-info.active, .btn-outline-info:hover {
  background-color: #2a4a9a!important;
}

.style-jtk2q5g7 {
  position: relative;
  min-height: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.style-jtk2q5g7input {
  border-radius: 0;
  font: normal normal normal 14px/1.4em Brendon-Grotseque-regular, sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-width: 1px;
  background-color: rgba(236, 236, 234, 1);
  box-sizing: border-box !important;
  color: #000000;
  border-style: solid;
  border-color: transparent;
  padding: 3px;
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  text-overflow: ellipsis;
}

.style-jtk2q5g7input[type="number"] {
  -moz-appearance: textfield;
}

.style-jtk2q5g7input::-webkit-input-placeholder {
  color: #000000;
}

.style-jtk2q5g7input::-ms-input-placeholder {
  color: #000000;
}

.style-jtk2q5g7input::placeholder {
  color: #000000;
}

.style-jtk2q5g7input:hover, .style-jtk2q5g7input[data-preview~="hover"] {
  border-width: 1px;
  background-color: rgba(236, 236, 234, 0.5);
  border-style: solid;
  border-color: transparent;
}

:not(.style-jtk2q5g7_with-validation-indication) .style-jtk2q5g7input:focus, :not(.style-jtk2q5g7_with-validation-indication) .style-jtk2q5g7input[data-preview~="focus"] {
  border-width: 1px;
  background-color: rgba(236, 236, 234, 0.5);
  border-style: solid;
  border-color: transparent;
}

.style-jtk2q5g7_with-validation-indication .style-jtk2q5g7input:invalid {
  border-width: 1px;
  background-color: rgba(180, 90, 212, 0.17);
  border-style: solid;
  border-color: rgba(255, 64, 64, 1);
}

.style-jtk2q5g7_with-validation-indication .style-jtk2q5g7input:not(:invalid):focus, .style-jtk2q5g7_with-validation-indication .style-jtk2q5g7input[data-preview~="focus"] {
  border-width: 1px;
  background-color: rgba(236, 236, 234, 0.5);
  border-style: solid;
  border-color: transparent;
}

.style-jtk2q5g7_left-direction .style-jtk2q5g7input {
  text-align: left;
}

.style-jtk2q5g7_right-direction .style-jtk2q5g7input {
  text-align: right;
  direction: rtl;
}

.style-jtk2q5g7_center-direction .style-jtk2q5g7input {
  text-align: center;
}

.style-jtk2q5g7 p {
  transition: opacity 0.5s ease 0s, border 0.5s ease 0s, color 0.5s ease 0s;
  opacity: 0;
  border-radius: 0;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 100%;
  width: 140px;
  padding: 10px;
  font-size: 13px;
  line-height: 16px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
}

.style-jtk2q5g7 p:after {
  content: "▼";
  position: absolute;
  bottom: -12px;
  right: 10px;
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
  border-radius: 0;
}

.style-jtk2q5g7[data-disabled="true"] .style-jtk2q5g7input, .style-jtk2q5g7[data-preview~="disabled"] .style-jtk2q5g7input {
  background-color: rgba(255, 255, 255, 1);
  color: #DBDBDB;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(219, 219, 219, 1);
}

.style-jtk2q5g7[data-error="true"] .style-jtk2q5g7input, .style-jtk2q5g7[data-state~="invalid"] .style-jtk2q5g7input, .style-jtk2q5g7[data-preview~="error"] .style-jtk2q5g7input {
  border-width: 1px;
  background-color: rgba(180, 90, 212, 0.17);
  border-style: solid;
  border-color: rgba(255, 64, 64, 1);
}

.style-jtk2q5g7label {
  font: normal normal normal 12px/1.4em avenir-lt-w01_85-heavy1475544, sans-serif;
  color: #FE6060;
  word-break: break-word;
  display: inline-block;
  line-height: 1;
}

.style-jtk2q5g7_required .style-jtk2q5g7label::after {
  content: " *";
  color: rgba(254, 96, 96, 1);
}

.style-jtk2qtl6_with-validation-indication select:not([data-preview~="focus"]):invalid {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 64, 64, 1);
  background-color: rgba(180, 90, 212, 0.17);
}

.style-jtk2qtl6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.style-jtk2qtl6 select {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  font: normal normal normal 14px/1.4em brandon-grot-w01-light, sans-serif;
  background-color: rgba(236, 236, 234, 1);
  color: #000000;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  margin: 0;
  padding: 0 45px;
  cursor: pointer;
  position: relative;
  max-width: 100%;
  min-width: 100%;
  min-height: 10px;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.style-jtk2qtl6 select option {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.style-jtk2qtl6 select.style-jtk2qtl6_placeholder-style {
  color: #FE6060;
}

.style-jtk2qtl6 select.style-jtk2qtl6_extended-placeholder-style {
  color: #888888;
}

.style-jtk2qtl6 select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.style-jtk2qtl6 select::-ms-expand {
  display: none;
}

.style-jtk2qtl6 select:focus::-ms-value {
  background: transparent;
}

.style-jtk2qtl6 select:not(.style-jtk2qtl6_mobileCollection):hover, .style-jtk2qtl6 select:not(.style-jtk2qtl6_mobileCollection)[data-preview~="hover"] {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: rgba(236, 236, 234, 0.5);
}

.style-jtk2qtl6 select:not(.style-jtk2qtl6_mobileCollection):focus, .style-jtk2qtl6 select:not(.style-jtk2qtl6_mobileCollection)[data-preview~="focus"] {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: rgba(236, 236, 234, 0.5);
}

.style-jtk2qtl6 select:not(.style-jtk2qtl6_mobileCollection)[data-error="true"], .style-jtk2qtl6 select:not(.style-jtk2qtl6_mobileCollection)[data-preview~="error"] {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 64, 64, 1);
  background-color: rgba(180, 90, 212, 0.17);
}

.style-jtk2qtl6 select:not(.style-jtk2qtl6_mobileCollection):disabled, .style-jtk2qtl6 select:not(.style-jtk2qtl6_mobileCollection)[data-disabled="true"], .style-jtk2qtl6 select:not(.style-jtk2qtl6_mobileCollection)[data-preview~="disabled"] {
  border-width: 2px;
  border-color: rgba(204, 204, 204, 1);
  color: #D4D4D4;
  background-color: transparent;
}

.style-jtk2qtl6 select:not(.style-jtk2qtl6_mobileCollection):disabled+.style-jtk2qtl6arrow, .style-jtk2qtl6 select:not(.style-jtk2qtl6_mobileCollection)[data-disabled="true"]+.style-jtk2qtl6arrow, .style-jtk2qtl6 select:not(.style-jtk2qtl6_mobileCollection)[data-preview~="disabled"]+.style-jtk2qtl6arrow {
  border-width: 2px;
  border-color: rgba(204, 204, 204, 1);
  color: #D4D4D4;
  border: none;
}

.style-jtk2qtl6arrow {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  height: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.style-jtk2qtl6arrow .style-jtk2qtl6_svg_container {
  width: 12px;
}

.style-jtk2qtl6arrow .style-jtk2qtl6_svg_container svg {
  height: 100%;
  fill: rgba(0, 0, 0, 1);
}

.style-jtk2qtl6_left-direction {
  text-align-last: left;
}

.style-jtk2qtl6_left-direction .style-jtk2qtl6arrow, .style-jtk2qtl6_left-direction select:hover+.style-jtk2qtl6arrow, .style-jtk2qtl6_left-direction select[data-preview~="hover"]+.style-jtk2qtl6arrow, .style-jtk2qtl6_left-direction select:focus+.style-jtk2qtl6arrow, .style-jtk2qtl6_left-direction select[data-preview~="focus"]+.style-jtk2qtl6arrow, .style-jtk2qtl6_left-direction[data-preview~="focus"] .style-jtk2qtl6arrow, .style-jtk2qtl6_left-direction select[data-error="true"]+.style-jtk2qtl6arrow, .style-jtk2qtl6_left-direction select[data-preview~="error"]+.style-jtk2qtl6arrow {
  border-left: 0;
}

.style-jtk2qtl6_left-direction .style-jtk2qtl6arrow {
  right: 0;
}

.style-jtk2qtl6_right-direction {
  text-align-last: right;
  direction: rtl;
}

.style-jtk2qtl6_right-direction .style-jtk2qtl6arrow, .style-jtk2qtl6_right-direction select:hover+.style-jtk2qtl6arrow, .style-jtk2qtl6_right-direction select[data-preview~="hover"]+.style-jtk2qtl6arrow, .style-jtk2qtl6_right-direction select:focus+.style-jtk2qtl6arrow, .style-jtk2qtl6_right-direction select[data-preview~="focus"]+.style-jtk2qtl6arrow, .style-jtk2qtl6_right-direction[data-preview~="focus"] .style-jtk2qtl6arrow, .style-jtk2qtl6_right-direction select[data-error="true"]+.style-jtk2qtl6arrow, .style-jtk2qtl6_right-direction select[data-preview~="error"]+.style-jtk2qtl6arrow {
  border-right: 0;
}

.style-jtk2qtl6_right-direction .style-jtk2qtl6arrow {
  left: 0;
}

.style-jtk2qtl6_center-direction {
  text-align-last: left;
  text-align-last: center;
}

.style-jtk2qtl6_center-direction .style-jtk2qtl6arrow, .style-jtk2qtl6_center-direction select:hover+.style-jtk2qtl6arrow, .style-jtk2qtl6_center-direction select[data-preview~="hover"]+.style-jtk2qtl6arrow, .style-jtk2qtl6_center-direction select:focus+.style-jtk2qtl6arrow, .style-jtk2qtl6_center-direction select[data-preview~="focus"]+.style-jtk2qtl6arrow, .style-jtk2qtl6_center-direction[data-preview~="focus"] .style-jtk2qtl6arrow, .style-jtk2qtl6_center-direction select[data-error="true"]+.style-jtk2qtl6arrow, .style-jtk2qtl6_center-direction select[data-preview~="error"]+.style-jtk2qtl6arrow {
  border-left: 0;
}

.style-jtk2qtl6_center-direction .style-jtk2qtl6arrow {
  right: 0;
}

.style-jtk2qtl6_mobileMenuContainer {
  border: 0;
}

.style-jtk2qtl6_mobileMenuContainer .style-jtk2qtl6arrow .style-jtk2qtl6_svg_container .style-jtk2qtl6icon {
  fill: #000000;
}

.style-jtk2qtl6label {
  font: normal normal normal 12px/1.4em avenir-lt-w01_85-heavy1475544, sans-serif;
  color: #FE6060;
  word-break: break-word;
  display: inline-block;
  line-height: 1;
}

.style-jtk2qtl6_required .style-jtk2qtl6label::after {
  content: " *";
  color: rgba(254, 96, 96, 1);
}

.style-jtk2qtl6_selector-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  position: relative;
}

.message-btn:disabled {
  border-color: #686a6a;
  background-color: #686a6a;
  color: white;
}

.message-btn {
  border-color: #686a6a;
  background-color: #686a6a;
  color: white;
}

.back-button {
  color: #007bff
}

.back-button:hover {
  color: #2a4a9a
}

.MuiPaper-elevation1 {
  box-shadow: none!important;
}

.react-calendar__tile--active {
  background: #2a4a9a;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: #2a4a9a!important;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled {
  background: #2a4a9a!important;
}

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-outline {
  border: none;
}

.nav.navbar .cart-no {
  right: none;
  left: none;
}

@media only screen and (max-width: 1023px) {
  .checkout-navbar {
    height: 82px !important;
  }
}



@media (max-width: 992px) {
  .nav.navbar .search, nav.navbar .right-col a.dropdown-toggle {
    margin-bottom: 0px !important;
  }
  .image-overlying-text {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 80%;
  }
  .home-first-image-div {
    
  
  }
  .carousel-caption {
    text-align: center;
    top: 172px;
    left: 50%;
    width: 80%;
    position: absolute;
    margin: 0px;
    text-align: justify;
    -moz-text-align-last: center;
    text-align-last: center;
    transform: translate(-50%, -50%);
  }
  .for-companies {
    top: 375px;
  }
  .for-companies-mail-input {
    vertical-align: middle;
    height: 30px;
    min-height: 30px;
    width: 165px;
  }
  .for-companies-mail-input-btn {
    vertical-align: middle;
    height: 30px;
    min-height: 30px;
    width: 108px;
  }
  .checkout-navbar {
    position: sticky;
    top: 0;
    z-index: 999;
  }
}


/*******************************
* MODAL AS SideDrawer
*******************************/

.modal-right .modal-content, .modal-left .modal-content {
  border: 0px;
  border-radius: 0px;
}

.modal-right .modal .modal-dialog, .modal-left .modal .modal-dialog {
  position: fixed;
  margin: auto;
  width: 75%;
  max-width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal-left .close {
  padding-top: 25px;
  padding-right: 25px;
}

.modal-left .modal-title {
  padding-top: 2px;
}

.modal-right .modal .modal-content, .modal-left .modal .modal-content {
  height: 100%;
  overflow-y: auto;
}

/* .modal-right .modal .modal-body {
	padding: 15px 15px 80px;
} */

.modal-right .modal.fade .modal-dialog {
  right: -500px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal-left .modal.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal-right .modal.fade.show .modal-dialog {
  right: 0;
}

.modal-left .modal.fade.show .modal-dialog {
  left: 0;
}

.modal-left .modal-header {
  height: 65px;
}

.add-more-articles-short {
  display: none
}

@media (min-width: 416px) and (max-width: 560px){
  .add-more-articles-short {
    display: inline-block;
  }
}

@media (max-width: 560px) {
  .add-more-articles {
    display: none;
  }
}

a.col-sm-auto.mb-2:hover {
  text-decoration: none;
  transform: scale(102%);
}

.links-modal {
  font-size: 20px;
  /* padding: 0;
  margin: 0;
  min-width: 320px;
  max-width: 320px; */
  /* left: 0;
  top: 0;
  bottom: 0; */
  /* min-height: 100%;
  height: 100%;  */
}

.links-modal .modal-header {
  background-color: #686a6a;
}

.links-modal .nav-item {
  padding-left: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.links-modal .modal-body {
  margin: 0px;
  padding: 0px;
}

.links-modal .nav-link {
  color: #2a4a9a;
}

.popover {
  z-index: 100000;
}

.popover-header {
  font-family: Brendon-Grotseque-bold;
}

.popover-body {
  font-family: Brendon-Grotseque-light;
}

.nav-link.shopping-button:not(.active):hover {
  background-color: rgb(128, 128, 128);
  color: white!important;
}

.nav-link.shopping-button:hover {
  background-color: white !important;
}

.shopping-button {
  border: 1.4px solid;
  border-radius: 5px;
  padding-left: 10px!important;
  padding-right: 10px!important;
  padding-top: 11px;
  padding-bottom: 7px;
  margin-right: 15px !important;
}

.shopping-button:hover span {
  color: black !important;
}

.shopping-button span {
  color: white !important;
}

.dropdown-toggle {
  gap: 6px;
}
.cart-no:not(:empty) + span {
  margin-left: 3px;
}
.dropdown-toggle .icon-cart {
  height: 21px;
  display: block;
  transform: translateY(0px);
}
.navbar .price-wrapper {
  display: flex;
  gap: 0px; 
}
@media screen and (max-width: 600px){
  .navbar-toggler {
    margin-left: -11px;
  }
  .dropdown-toggle {
    align-items: center;
    margin-right: 0 !important;
  }
  .cart-no {
    top: -5px !important;
  }
  .dropdown-toggle .icon-cart {
  }
  .navbar .price-wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 0px; 
  }
  .navbar .price-wrapper span {
    line-height: 18px;
  }
  .shopping-button {
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 400px){

  .shopping-button {
    margin-right: 5px !important;
    padding-top: 2px !important;
    padding-bottom: 0px !important;
    height: auto;
    display: flex;
    align-items: center;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .dropdown-toggle  {
    margin-left: 5px !important;
  }
}
@media screen and (max-width: 375px){
  
  .shopping-button span {
    font-size: 12px;
  }
}
a.nav-link.shopping-button:not(.active):hover {
  background-color: white !important;
  color: black !important;
}


@media (max-width: 1300px) {
  nav.navbar a.nav-link {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (max-width: 1093px) {
  nav.navbar a.nav-link {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.img-prices {
  position: absolute;
  width: 470px;
  height: auto;
  right: calc(50vw - 54px);
  top: 35px;
}

@media (max-width: 1019px) {
  .img-prices {
    width: 550px;
    top: 35px;
  }
}

@media (max-width: 828px) {
  .img-prices {
    width: 590px;
    top: 35px;
  }
}

@media (max-width: 719px) {
  .img-prices {
    display: none;
  }
}

.img-services {
  position: absolute;
  width: 330px;
  height: auto;
  left: calc(50vw - 30px);
  top: 30px;
}

@media (max-width: 968px) {
  .img-services {
    width: 370px;
  }
}

@media (max-width: 778px) {
  .img-services {
    width: 395px;
  }
}

@media (max-width: 740px) {
  .img-services {
    display: none;
  }
}

.img-eco {
  position: absolute;
  width: 400px;
  height: auto;
  right: 50%;
  top: 55px;
}

@media (max-width: 767px) {
  .img-eco {
    width: 690px;
    top: 45px;
  }
}

@media (max-width: 638px) {
  .img-eco {
    display: none;
  }
}

.img-company {
  position: absolute;
  width: 420px;
  height: auto;
  left: 50%;
  top: 70px;
}

@media (max-width: 784px) {
  .img-company {
    width: 550px;
    top: 45px;
  }
}

@media (max-width: 638px) {
  .img-company {
    display: none;
  }
}

.img-how-it-works {
  position: absolute;
  width: 390px;
  height: auto;
  left: 50%;
  top: calc(50vh - 245px)
}

@media (max-width: 541px) {
  .img-how-it-works {
    display: none;
  }
}

.img-contact {
  position: absolute;
  width: 560px;
  height: auto;
  left: calc(50vw - 160px);
  top: 40px;
}

@media (max-width: 849px) {
  .img-contact {
    width: 690px;
  }
}

@media (max-width: 590px) {
  .img-contact {
    display: none;
  }
}

.img-why-wos {
  position: absolute;
  width: 340px;
  height: auto;
  left: calc(50vw - 90px);
  top: 60px;
}

@media (max-width: 590px) {
  .img-why-wos {
    left: 50%;
    transform: translateX(-50%);
    max-width: 80%;
  }
}

.img-our-values {
  position: absolute;
  width: 0;
  width: 100%;
  left: 0;
  top: -20px;
  height: 100%;
}

.home-title-img {
  max-width: 250px;
  display: block;
  margin: 50px auto 0px;
}
.home-bg-image-bg {
  transform: scale(1.1);
  position: absolute;
  width: 100%;
  height: 100%;
}
.home-bg-image {
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0px 20px;
}

.home-bg-image:after {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  content: "";
  top: 0;
  left: 0;
  z-index: 0;
}

@media (max-width: 590px) {
 
}

@media (min-width: 590px) {

  .home-title-img {
    max-width: none;
    max-height: 60px;
  }
}



@media (max-width: 991px) {
  .img-our-values {
    display: none;
  }
}

.h1 {
  font-family: holtzman-textured!important;
  font-size: 60px!important;
  color: #212529!important;
  letter-spacing: 1px;
}

.h2 {
  font-family: holtzman-textured!important;
  font-size: 40px!important;
  color: #212529!important;
}

.h2-service {
  font-family: holtzman-textured!important;
  font-size: 40px!important;
}

.t1 {
  font-family: Brendon-Grotseque-light;
  font-size: 19px!important;
  color: #212529!important;
}

.t2 {
  font-family: Brendon-Grotseque-light;
  font-size: 15px!important;
  color: #212529!important;
}

.ReactTable .rt-th, .ReactTable .rt-td {
  text-align: center;
}

.navbar-expand-xl .navbar-nav .nav-link {
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

@media screen and (max-width: 450px){
  .navbar-brand img {
    width: 50px;
    height: auto !important;
    display: flex;
    align-items: center;
  }
}

.navbar-toggler {
  color: white;
}

@media screen and (max-width: 991px){
  .mobile-center {
    text-align: center !important;
  }

  .service-page-section-header {
    margin-top: 20px !important;
  }

  .service-page-section-img {
    margin-top: 40px !important;
  }

  .service-page-text-container, .service-page-text-container-right {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}



@media (max-width: 1300px) {
  .navbar-margin {
  }
}

.h1 {
  font-weight: unset;
}

.h2 {
  font-weight: unset;
}

.service-page-text-container-right {
  margin-left: 20%;
  margin-right: 5%;
}

.button-services {
  position: absolute;
  top: calc(50vh + 182px);
  left: calc(50vw - 126px);
  font-size: 16px;
  border: 2px solid #212529;
  font-weight: 500;
  line-height: 50px;
  padding: 0 50px;
  border-radius: 60px !important;
  background-color: transparent;
  color: #212529;
}

.button-services, .button-services:focus {
  outline: none;
}

.button-services:not([disabled]):hover {
  background-color: rgba(158, 158, 158, 0.2);
}

.cart-item {
  background-color: white;
}

.note-btn-item-sm, .note-btn-item-lg {
  outline: none!important;
}

.MuiInputLabel-formControl {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.carousel-text-content a {
  color: rgb(42, 74, 154);
}

.btn-blue {
  line-height: 50px;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  background-color: rgb(42, 74, 154) !important;
  color: white !important;
  border-radius: 5px;
  border: 2px solid rgb(42, 74, 154);
  padding: 0px 20px;
  letter-spacing: 1px;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  transform-origin: center;
  &:hover {
    text-decoration: none;
    transform: scale(1.05);
  }
  @media screen and (max-width: 991px){
    font-size: 14px;
    white-space: nowrap;
    line-height: 44px;
    padding: 0px 15px;
  }
  svg {
    color: white;
    width: 30px;
    height: auto;
    stroke: white;
    transition: all 300ms ease;
    path {
      stroke: white;
    }
  }

}

.sticky-btn, .sticky-a {
  position: fixed;
  bottom: 40px;
  display: block;
  z-index: 900;
  right: 40px;
  @media screen and (max-width: 991px){
    bottom: 20px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  &:hover {
    text-decoration: none;
    svg {
      transform: translateX(10px);
    }
  }
  &.sticky-a.left {
    left: 40px;
    margin-left: 0 !important;
    &:hover {
      svg {
        transform: translateX(0px) scale(1.2);
       
      }
    }
   
    button {
      background-color: white !important;
      color: rgb(42, 74, 154) !important;
      padding-left: 5px;
      gap: 10px;
    }
    svg  {
      width: 40px;
      //transform: scale(1.1);
      
      rect {
        fill: none;
      }
      path {
        fill: rgb(42, 74, 154) !important;
      }
    }
    @media screen and (max-width: 991px){
      bottom: 20px;
      left: 20px;
      right: auto;
      transform: none !important;
      button {
        padding-left: 0;
        overflow: hidden;

      }
      svg {
        transform: scale(1.2) !important;
        margin-right: 3px;
      }
    }
  }
  &.sticky-a:not(.left){
    @media screen and (min-width: 731px) and (max-width: 991px){
      bottom: 20px;
      right: 20px !important;
      left: auto !important;
      transform: none;
    }
  }
  &.sticky-a {
    @media screen and (max-width: 600px){
      width: 100%;
     
      button {
        width: calc(100% - 20px);
        margin-left: 10px !important;
        text-align: center;
        justify-content: center;
      }
    }
    @media screen and (max-width: 730px){
      
      &.fixed {
        left: 50% !important;
        right: auto !important;
        bottom: 10px;
        transform: translateX(-50%) !important;

        &:not(:last-child) {
          bottom: 30px !important;
        }
      }
      &:not(.fixed){
        button {
          bottom: auto;
        }
      }
      &.left {
        button {
          position: relative;
          padding-left: 48px;
        }
        svg {
          position: absolute;
          left: 0px;
        }
      }
      
      button {
        line-height: 40px !important;

      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
  > button, &.sticky-btn {
    line-height: 50px;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    background-color: rgb(42, 74, 154) !important;
    color: white !important;
    border-radius: 5px;
    border: 2px solid rgb(42, 74, 154);
    
    padding: 0px 20px;
    letter-spacing: 1px;
    box-shadow: 0px 0px 20px #888;
    display: flex;
    gap: 10px;
    align-items: center;
    transform-origin: center;
    &:hover {
      text-decoration: none;
    }

    @media screen and (max-width: 991px){
      font-size: 14px;
      white-space: nowrap;
      line-height: 44px;
      padding: 0px 15px;
      bottom: 20px;
    }

  }



    svg {
      color: white;
      width: 30px;
      height: auto;
      stroke: white;
      transition: all 300ms ease;
      path {
        stroke: white;
      }
    }




}

.max-text {
  max-width: 800px;
  margin: 0 auto;
}

.card-body {
  .checkout-page-address-edit-btn {
    position: absolute;
    width: 30px;
    right: 10px;
    top: 12px;
  }
  .address {
    padding-right: 45px;
  }
}

.card-header{
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font: normal normal normal 16px/1.4em Brendon-Grotseque-bold, sans-serif;
  padding: 10px 0px;

  .express {
    font-family: "Brendon-Grotseque-regular", sans-serif;
    text-transform: none;
    font-size: 14px;
    margin-top: 2px;
    label {
      margin: 0px 0px 0px 0px;
      margin-left: 5px;
    }
  }
}

.sticky-a:not(.fixed) {
  position: static;
  margin-left: auto;
  transform: none;
}

@keyframes slideInFromBottom {
  0% {
    transform: translate(-50%, -100%);
  }
  100% {
      transform: translate(-50%, -50%);
  }
}

@keyframes slideInFromBottomMobile {
  0% {
    transform: translateY(100%);
  }
  100% {
      transform: translateY(0);
  }
}

.page--products+.content:has(.cart-config.temp){
  &:after {
    position: fixed;
    height: 1000vh;
    width: 1000vh;
    top: -50vh;
    left: -50vw;
    background-color: rgba(0,0,0,0.8);
    z-index: -1;
    content: "";
    animation: none;
    transform: none !important;
    z-index: 1000;
  }
}

.cart-config.temp {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 700px;
  transform: translate(-50%, -50%);

  background-color: transparent;
  z-index: 1001;
  max-height: 90dvh;
  height: auto;

  .shopping-item-section {
    padding-bottom: 0;
  }  

  @media screen and (max-width: 991px){
    
    width: 100% !important;
    bottom: 0;
    left: auto;
    //transform: none;
    //transform: translateY(0%);
    top: auto;
    display: flex;
    animation: slideInFromBottomMobile 200ms linear forwards;

  }

  .quantity-price-part {
    margin-bottom: 0 !important;
  }

  .quantity-price-part > div {
    @media screen and (min-width: 600px){

    display: flex;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
    .quantity-label {
      padding-top: 0;
    }


    }
  }

  .item-header {
    margin-right: 10px !important;;
  }

  .shopping-item-section {
    max-width: none;
  }

  .cart-item.giftcard .col-lg {
    flex-basis: 0 !important;
    flex-grow: 1;
  }

  .col-lg {
    flex-basis: 25%;
    justify-content: flex-start;
    padding: 0px 10px;
    flex-grow: 0;

    @media screen and (max-width: 991px){
      flex-basis: 50%;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 650px){
      flex-basis: 100% !important
    }
    
    .item-header {
      overflow-wrap: break-word;
      hyphens: auto;
    }
  }
  .col-lg.laundry:has(ul:only-child li:first-child + li:last-child) {
    display: none;
  }
  &.col--0, &.col--1, &.col--2 {
    .col-lg {
      flex-basis: 50%;
    }
  }
  &.col--3 {
    .col-lg {
      flex-basis: 33.3%;
    }
  }
  &.col--4 {
    width: 900px;
  }
  &.col--5 {
    width: 800px;

    .col-lg {
      flex-basis: 33.3%;
      @media screen and (max-width: 991px){
        flex-basis: 50%;
      }
    }
    
  }

  /*&.wide .col-lg {
    flex-basis: 25%;
    flex-grow: 0;
    @media screen and (max-width: 991px){
      flex-basis: 50%;

      &:nth-child(2) ul:first-child {
        margin-bottom: 20px;
      }
    }
    @media screen and (min-width: 651px) and (max-width: 991px){
      &:nth-child(2):has(ul + ul){
        display: flex;
        flex-basis: 100%;
        gap: 15px;
        ul {
          width: 50%;
          
        }
      } 
    }
    
  }*/

  .image-price-container {
    flex-basis: 100% !important;
  }

  .item-header {
    br {
      display: none;
    }
  }

  .image-price-container-image {
    @media screen and (max-width: 450px){
      height: 75px;
      width: 75px;
    }
  }

  .cart-content > div {
    justify-content: flex-start !important;
  }

  .cart-config-inner {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 991px){
      
      max-height: 100%;

      .cart-content {
        overflow: auto;
        max-height: calc(100% - 71px);
        margin-top: 0px;
        padding-bottom: 120px;
        background-color: white;


      }
    }
    @media screen and (max-width: 630px){
      .cart-content {
        padding-bottom: 80px;
      }
    }
  }

  .price-cart-item-left {
    top: 20px !important;
  }

  .img-wrapper {
    height: 100%;
    width: 0;
    padding-right: 100%;
  }

  .cart-config-header {
    border-radius: 0px;
    color: white;
    padding: 10px 20px;
    display: flex;
    gap: 15px;
    align-items: center;
    position: relative;
    background-color: rgb(42, 74, 154);

    &.added {
      background-color:#009B72;
    }

    .left {
    }
    &-top {
      text-transform: uppercase;
      letter-spacing: 1px;

    }
    .right {
      width: 100%;
      padding-right: 40px;
      line-height: 1.3;
    }
    &-bottom {
      margin-top: 2px;
      font-size: 12px;
    }
    svg {
      width: 40px;
      height: 40px;
      fill: white;
      color: white;
      text-shadow:  0 0 1px red;
    }

    &-top {
      font-size: 18px;
    }
    &-bottom {
      font-size: 16px;
      @media screen and (max-width: 630px){
        font-size: 14px;
      }
    }
  }

  .price-cart-item-left {
    right: 20px;
  }

  .note-btn-item-lg {
      right: 20px;
  }

  .image-price-container-image {
    position: relative;
  }

  .close-overlay {
    position: absolute;
    background-color: transparent;
    box-shadow: none;
    border: none;
    color: white;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  & > .row:last-child {
    margin: 0;
    justify-content: flex-end;
    
  }

  .col-lg:empty {
    display: none;
  }

  .col-lg {
    //width: 50%;
  }

  .quantity-price-part {
    top: 0px;
    position: relative;
    margin-bottom: 10px;
    left: 0;
  }


  .overlay-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom:15px;
    padding-right: 15px;
    padding-left: 15px;
    border-top: 1px solid lightgray;
    padding-top: 15px;
    gap: 20px;
    margin-top: 20px;

    @media screen and (max-width: 991px){
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: 1000;
      background-color: rgba(255,255,255,0.9);
      box-shadow: 0px 0px 10px lightgray;
    }

  
    @media screen and (max-width: 630px){
      flex-direction: column;
      gap: 5px;
      padding: 15px 15px 15px 15px;
    }





    .overlay-button {
      white-space: nowrap;
      display: block;
      height: auto !important;
      width: auto;
      
      padding: 0px 20px !important;
      line-height: 50px;
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;


      @media screen and (max-width: 630px){
        line-height: 40px;
        padding: 0px 10px !important;
        font-size: 14px;
        width: 100%;
        justify-content: center;
      }

      
    }

    .add-more {
      background-color: rgb(42, 74, 154) !important;
      color: white !important;
      border-radius: 5px;
      border: 2px solid rgb(42, 74, 154);

      &:hover {
        text-decoration: none;
        transform: scale(1.05);
      }

    }

    .to-basket {
      background-color: white !important;
      color: rgb(42, 74, 154) !important;
      border: 2px solid transparent;
      padding-right: 0 !important;
      display: flex;
      align-items: center;
      gap: 10px;

      &:hover, &:focus {
        text-decoration: none;
        transform: translateX(5px);
      }

      svg {
        height: auto;
        width: 24px;
        g path {
          stroke: rgb(42, 74, 154);
        }
      }
    }
    
  }

  .image-price-container {
    flex-basis: auto;
    padding: 10px 0px;
    display: flex;
    height: auto;
    align-items: center;
    padding-left: 15px;
    width: 100%;
    padding-right:150px;
    max-width: none;
    position: relative;
    margin: 0;
    flex-direction: row;
    min-height: 0;

    img {
    }
  }

  .quantity-price-part {
    top: 0px;
    position: relative;
    margin-bottom: 10px;
    left: 0;
  }

  .price-section.col-lg {
    display: none !important;
  }

  .image-price-container {

  }

  .price-cart-item-left {
    top: 20px !important;
    position: absolute !important;
    right: 20px !important;
    visibility: visible;
  }
  .note-btn-item-lg {
    visibility: visible;
  }
  

  .cart-item {
    position: relative;
    margin: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  

    @media screen and (max-width: 991px){
      margin-bottom: 0 !important;
    }

  }

  .shopping-item-section {
    min-height: 0px;
  }

  & > .row:first-child {
    margin: 0;
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;

  }

  
}

h2.product-category {
  scroll-margin-top: 75px;
  &:first-child {
    margin-top: 75px;
  }
  @media screen and (max-width: 992px){
    font-size: 24px !important;
  }
}

.page--products + .content {
  h1 {
    line-height: 1;
  }
  h2:first-child {
    margin-top: 30px;
  }
}

@media screen and (max-width: 992px){
  .container {
    max-width: calc(100vw - 20px);
  }
}

.products-help {
  font-family: 'Brendon-Grotseque-light';
  font-size: 19px;
  max-width: 800px;
  margin: 0 auto;
}

.products-help.truncated {
  @media screen and (max-width: 991px){
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
  }
}

.show-help {
  color: #212529;
  display: block;
  margin-top: 3px;
  text-decoration: underline;
  @media screen and (min-width: 992px){
    display: none;
  }
}

.product-filters {
  background-color: white;
  padding-left: 65px;
  flex-wrap: nowrap;
  margin: 0;
  width: 100%;
  position: relative;
  margin: 0 auto;
  height: 44px;
  padding-right: 15px;

  &.fixed {
    position: fixed;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);  top: 60px;
    height: 64px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 60px;

    button {
     // margin-top: 10px;
    }
    .search-box-with-icon {
      left: 7px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  
  }
  &:not(.fixed){
    .active {
      background-color: transparent !important;
      color: black;
    }
  }

  &.search-active {
    .search-box-with-icon {
      width: calc(100% - 25px);
      input {
        display: block;
      }
    } 
    .scrollable-menu {
      display: none;
    }
    button {
      color: white;
      background-color: rgb(42, 74, 154) !important;
      border-color: rgb(42, 74, 154) !important;
    }
    .back {
      display: inline-block;
      border: 1px solid;
      border-radius: 4px;
      text-align: center;
      text-decoration: none;
      padding: 0px 10px;
      line-height: 42px;
      text-transform: uppercase;
      font: normal normal normal 14px/42px Brendon-Grotseque-bold, sans-serif

    }
  }

  .back {
    display: none;
    white-space: nowrap;
    margin-left: 20px;
    color: black;
    text-decoration: underline;
    align-self: center;
    padding-right: 25px;
  }

  .search-box-with-icon {
    position: absolute;
    left: 15px;
  //  align-items: flex-end;
    top: 0;
    height: 100%;
    width: 44px;
    min-width: 0;
    max-width: none;
    opacity: 1;
    button {
      width: 44px;
      height: 44px;
      flex-shrink: 0;
      border: 1px solid;
      background-color: transparent;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
    }
    input {
      display: none;
      height: auto;
      margin-bottom: 1px;
    }
    
  }

  .scrollable-menu {
    display: flex;
    overflow-x: auto; /* Allow scrolling */
    scrollbar-width: none; /* Firefox: Hide the scrollbar */
    -ms-overflow-style: none; /* IE 10+: Hide scrollbar */

    @media screen and (max-width: 992px){
      width: calc(100vw + 20px);
      max-width: none;
    }

    @media screen and (min-width: 992px){
      max-width: none;
      margin-right: calc(-1*((100vw - 960px)/2 - 5px));
    }
    @media screen and (min-width: 1200px){

      max-width: none;
      margin-right: calc(-1*((100vw - 1140px)/2 - 5px));
    }


    a.active {
      background-color: rgb(42, 74, 154);
    }

    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar */
    }

    a {
      width: auto;
      padding: 10px 10px;
      font-size: 18px;
      @media screen and (max-width: 992px){
        font-size: 16px;
      }
      &.active {
        border-radius: 5px;
      }
    }
  }
}

.jumbotron h2 {
  letter-spacing: 1px;
}

.pricing-group {
  margin-bottom: 60px;
  h2 {
    font-family: holtzman-textured !important;
    font-size: 40px !important;
    color: #212529 !important;
    padding-bottom: 30px;
  }
  &-entry {
    display: flex;
    justify-content: space-between;
    span {
      text-align: left;
      &+span {
        text-align: right;
        flex-shrink: 0;
      }
    }
  }
  &-entries {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 250px)); /* 250px min, dynamic max */
    gap: 10px calc((100% - 750px)/2); /* Spacing between grid items */
  }
}

.pricing-row {
  padding: 5px 10px;
  color: black;
  &:hover {
    text-decoration: none;
    transform: scale(1.05);
  }
}
.pricing-row:nth-child(odd) {
  background-color: rgba(172, 199, 222, 0.5);

}

// Language Switcher
.nav-link.shopping-button + .dropdown {
  margin-right: -5px;
  margin-left: -5px;
}

.nav-link.shopping-button + .dropdown:has(+ .user.logged-in) {
  margin-right: 5px;
  margin-left: -5px;
}


.language-switcher-container  {
  position: absolute;
  bottom: 20px;
  left: 15px;
}

.language-switcher {
  background: transparent !important;
  border: 1px solid;
  color: white !important;
  font-family: "Brendon-Grotseque-regular";
  font-size: 18px;
  padding: 8px 12px !important;
  margin: 0 10px;
  border-radius: 3px;

  &[aria-expanded="true"] {
    &:after {
      transform: rotate(180deg);
    }
  }
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23ffffff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    height: 39px;
    width: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    content: "";
    margin-left: 5px !important;
  }

  &:hover, &:focus, &:active {
    background: rgba(255, 255, 255, 0.1) !important;
    box-shadow: none !important;
  }

  .modal & {
    border-color: black;
    color: black !important;

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23000000' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    } 
    

    @media screen and (min-width: 700px){
      display: none;
    }

    & + .dropdown-menu {
      .dropdown-item {
        color: black !important;
      }
    }
  }
  

  &::after {
    margin-left: 8px;
    vertical-align: middle;
    margin-top: -3px;
  }

  .navbar-expand-xl & {
    border: none;
    font-size: 14px;
    margin: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 39px;
    width: auto !important;
    margin-right: 0 !important;

    @media screen and (max-width: 699px){
      display: none;
    }
   
    

  }

  &  + .dropdown-menu {
    min-width: auto !important;
    border: none !important;
    border-radius: 0 !important;
    margin-top: 0 !important;
    padding: 0 !important;
    width: 86px !important;

  
    .dropdown-item {
      font-family: "Brendon-Grotseque-regular";
      font-size: 16px;
      padding: 8px 16px !important;
      text-align: center;
      margin-bottom: 0 !important;
  
      &:hover, &:focus {
        background: rgba(255, 255, 255, 0.1) !important;
      }
  
      &.active {
        background: rgba(255, 255, 255, 0.2) !important;
        margin-bottom: 0 !important;
        color: black;
      }
    }
  }
}

.carousel-text-content.t1 {
  white-space: pre-line;
}

