@charset "UTF-8";

@font-face {
  font-family: "clotheshub";
  src:url("./fonts/clotheshub.eot");
  src:url("./fonts/clotheshub.eot?#iefix") format("embedded-opentype"),
    url("./fonts/clotheshub.woff") format("woff"),
    url("./fonts/clotheshub.ttf") format("truetype"),
    url("./fonts/clotheshub.svg#clotheshub") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "clotheshub" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "clotheshub" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bag:before {
  content: "\61";
}
.icon-cart:before {
  content: "\62";
}
.icon-coin:before {
  content: "\63";
}
.icon-expand:before {
  content: "\64";
}
.icon-headphones:before {
  content: "\65";
}
.icon-heart:before {
  content: "\66";
}
.icon-profile:before {
  content: "\67";
}
.icon-search:before {
  content: "\68";
}
.icon-telephone:before {
  content: "\69";
}
.icon-truck:before {
  content: "\6a";
}
.icon-secure-shield:before {
  content: "\6b";
}
.icon-close:before {
  content: "\6c";
}
.icon-administrator:before {
  content: "\6d";
}
.icon-email:before {
  content: "\6e";
}
.icon-envelope:before {
  content: "\6f";
}
.icon-letter:before {
  content: "\70";
}
.icon-telemarketer-1:before {
  content: "\71";
}
.icon-telemarketer-2:before {
  content: "\72";
}
.icon-mail:before {
  content: "\73";
}
.icon-map:before {
  content: "\74";
}
.icon-map-1:before {
  content: "\75";
}
.icon-map-2:before {
  content: "\76";
}
.icon-map-3:before {
  content: "\77";
}
.icon-placeholder:before {
  content: "\78";
}
.icon-street-map:before {
  content: "\79";
}
.icon-support:before {
  content: "\7a";
}
.icon-telemarketer:before {
  content: "\41";
}
.icon-marker:before {
  content: "\42";
}
